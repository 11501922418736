export default {
    install(app) {
        app.config.globalProperties.$utils = this;
    },
    hasPermision(key) {
        let uesrId = localStorage.getItem("uesrId")
        if (uesrId == "root") {
            return true;
        }
        let s = localStorage.getItem("permision");
        if (!this.isEmpty(s)) {
            let arr = s.split(",");
            if (arr.includes(key)) {
                return true;
            }
        }
    },
    isEmpty(v) {  //判断传进来的值为空,返回true
        if (typeof v == "undefined" || v == "" || v == null) {
            return true;
        }
        if (typeof v == 'object') {
            if (Array.isArray(v) && v.length == 0) {
                return true;
            } else if (!Array.isArray(v)) {
                let a = JSON.stringify(v);
                if (a.length <= 2) {
                    return true
                }
            }
        }
        return false
    },


    clearObject(obj) {
        if (typeof obj == 'undefined' || obj == null || typeof obj != 'object') {
            return;
        }

        if (Array.isArray(obj)) {
            obj = [];
            return;
        }

        for (let key in obj) {
            let t = typeof obj[key];
            if (t == 'string') {
                obj[key] = ''
            } else if (t == 'number') {
                obj[key] = 0
            } else if (t == 'object') {
                if (Array.isArray(obj[key])) {
                    obj[key] = []
                } else {
                    obj[key] = {}
                }
            } else if (t == 'boolean') {
                obj[key] = false
            } else {
                obj[key] = null
            }
        }
    },
}
import { createApp } from 'vue'
import Api from './api/api';
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import utils from './utils/utils.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


let app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.use(router);
app.use(utils);
let api = new Api(router);
app.use(api);
app.use(ElementPlus);
app.mount('#app');
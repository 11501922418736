import axios from "axios";
import { ElMessage } from 'element-plus'
// import qs from "qs";

// let a = 'https://dbsxjn.cn:8084/';
let a = 'http://192.168.0.126:8080/';

class Api {
    errorMsg = false;
    router = null;
    constructor(rt) {
        this.router = rt;
    }
    install(app) {
        app.config.globalProperties.$api = this;
    }
    getApiServe() {
        return a;
    }
    api1(path, param, successbalck){
        let url = a + path;
        if (typeof param == 'undefined' || param == null) {
            param = {}
        }
        axios.post(url, param).then((res) => {
            if (res.data.errno == 501) {
                this.showError(res.data.errmsg)
                this.router.push({
                    path: "/",
                });
                localStorage.clear()
            } else {
                successbalck(res)
            }
        })
    }
    api(path, param, methods, successbalck) {
        // console.log("api");
        let url = a + path;
        if (typeof param == 'undefined' || param == null) {
            param = {}
        }
        // let token = localStorage.getItem("token");   //获取token
        // 添加请求拦截器
        axios.interceptors.request.use(function (config) {
            // 在发送请求之前做些什么
            let token = sessionStorage.getItem("token");
            // console.log("token",token);
            // config.headers.common['access_token'] = token
            config.headers.Authorization = `Bearer ${token}`
            // axios.defaults.headers.common['Admin-Token'] = token;
            return config;
        }, function (error) {
            // 对请求错误做些什么
            console.log("error", error);
            return Promise.reject(error);
        });
        // axios.defaults.headers.common['Admin-Token'] = token;     //设置请求头带上token
        // axios.config.headers.common['Admin-Token'] = token
        // let p = qs.stringify(param);
        if (methods == 'post') {
            axios.post(url, param).then((res) => {
                if (res.data.errno == 4001) {
                    this.showError(res.msg)
                    this.router.push({
                        path: "/layout",
                    });
                    localStorage.clear()
                } else {
                    successbalck(res)
                }
            })
        } else if (methods == 'get') {
            axios.get(url, { params: param }).then((res) => {
                if (res.data.errno == 4001) {
                    this.showError(res.msg)
                    this.router.push({
                        path: "/layout",
                    });
                    localStorage.clear()
                } else {
                    successbalck(res)
                }
            })
        } else if (methods == 'put') {
            axios.post(url, param).then((res) => {
                if (res.data.errno == 4001) {
                    this.showError(res.msg)
                    this.router.push({
                        path: "/layout",
                    });
                    localStorage.clear()
                } else {
                    successbalck(res)
                }
            })
        }else if(methods == 'delete'){
            axios.post(url+param).then((res) => {
                if (res.data.errno == 4001) {
                    this.showError(res.msg)
                    this.router.push({
                        path: "/layout",
                    });
                    localStorage.clear()
                } else {
                    successbalck(res)
                }
            })
        }


    }
    showError(msg) {
        if (this.errorMsg) {
            return
        } else {
            this.errorMsg = true;
        }
        ElMessage(
            {
                message: msg,
                type: 'error',
                onClose: () => {
                    this.errorMsg = false
                }
            }
        )

    }
}
export default Api
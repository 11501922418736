<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
*{
  margin: 0px;
  padding: 0px;
}
.mind{
  width: 100vw;
  height: 100vh;
}
</style>

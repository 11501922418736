import * as VueRouter from 'vue-router'
// import login from './components/pages/login.vue'
const routes = [
    { path: '/', component: () => import('./components/pages/home.vue'),children:[
        { path: '/login', component: () => import('./components/pages/login.vue')},
        { path: '/product', component: () => import('./components/pages/product.vue')},
        { path: '/solution', component: () => import('./components/pages/solution.vue')},
        { path: '/shopping', component: () => import('./components/pages/shopping.vue')},
        { path: '/about', component: () => import('./components/pages/about.vue')},
        { path: '/details', component: () => import('./components/pages/details.vue')},
        { path: '/ordeDdeta', component: () => import('./components/pages/ordeDdeta.vue')},
    ]},
    { path: '/layout', component: () => import('./components/pages/layout.vue')},
    { path: '/blayout', component: () => import('./components/backstage/blayout.vue'),children:[
        { path: '/bhome', component: () => import('./components/backstage/bhome.vue')},
        { path: '/border', component: () => import('./components/backstage/border.vue')},
        { path: '/bcommodity', component: () => import('./components/backstage/bcommodity.vue')},
        { path: '/bcategory', component: () => import('./components/backstage/bcategory.vue')},


    ]},
]

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes,
    mode:'history'//默认是hash，hash就会有#
})
export default router